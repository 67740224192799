<template>
  <div style="display:inline">
    <b-button @click="resync_vehicle()" size="sm" variant="primary"><font-awesome-icon icon="undo"/> Refresh </b-button>
    <br/>
    <br/>
    <loading v-if="isLoading"></loading>
    <b-table v-if="!isLoading" striped hover :items="vehicleData" :fields="vehicleFields" :per-page="perPage" :current-page="currentPage" show-empty>
      <template v-slot:cell(action)="row">
        <b-button @click="editVehicle(row.item)" variant="warning" size="sm">Detail Kendaraan</b-button>&nbsp;
      </template>
      <template #empty>
        <div class="text-center my-3">
          Tidak ada data yang tersedia saat ini.
        </div>
      </template>
    </b-table>
    <br/>
    <div class="d-flex justify-content-end" v-if="!isLoading">
      <b-pagination v-model="currentPage" :total-rows="vehicleData.length" :per-page="perPage"></b-pagination>
    </div>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent
  },
  props:{
    vehicleData: {
      type: [Array, Object],
      default: () => [],
    },
    userData: {
      type: [Array, Object],
      default: () => [],
    },
    isLoading:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      userid: null,
      perPage: 10, // Jumlah item per halaman
      currentPage: 1, // Halaman saat ini
      vehicleFields: [
        {key:'modelname',label:'Jenis Kendaraan'},
        {key:'plate',label:'Plat Nomor'},
        {key:'action',label:'Action'}
      ],
    };
  },
  methods: {
    init: function() {
    },
    editVehicle: function(e) {
      this.$router.push({ path: "/idp-kendaraan-detail/"+e.plate });
    },
    resync_vehicle: function(){
      this.$emit('syncVehicle',this.userData.id);
    }
  },
  mounted() {
    this.init();
  }
}
</script>