<template>
  <div style="display:inline">
    <b-table striped hover :items="dpoData" :fields="dpoFields" show-empty>
      <template v-slot:cell(action)="row">
        <b-button @click="viewDetail(row.item)" variant="primary" size="sm"><feather-icon icon="EyeIcon" /></b-button>
      </template>
      <template #empty>
        <div class="text-center my-3">
          Tidak ada data yang tersedia saat ini.
        </div>
      </template>
    </b-table>

    <b-modal v-model="infoDPOModal" centered title="Informasi Status DPO" hide-footer size="lg">
      <div class="row">
        <div class="col-md-8">
          <b-card>
            <img v-if="dpoDetail.image" :src="dpoDetail.image" style="width: 150px; border-radius: 0%;" class="profile-image" alt="Profile Image">
            <img v-else :src="require('@/assets/images/avatars/noimg.jpg')" style="width: 150px; border-radius: 0%;" class="profile-image" alt="Profile Image">
            <hr>
            <p>{{ dpoDetail.description }}</p>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card>
            <p>{{ dpoDetail.periode }}</p>
            <p>By : {{ dpoDetail.inserted_by_name }}</p>
          </b-card>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect
  },
  props:{
    user: {
      type: [Array, Object],
      default: () => [],
    },
    dpoData: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      userid: null,
      Loading: false,
      LoadingCrime: false,
      crimeModal: false,
      selectedLicenseType : null,
      infoDPOModal: false,
      formCrime:_interfaces.catatankriminal,
      crimeDetail: [],
      detailCrimeModal: false,
      dpoFields: [
        { key: "title", label: "Judul" },
        { key: "periode", label: "Description " },
        { key: "inserted_by_name", label: "By " },
        { key: "action", label: "Action" },
      ],
      selectedPasal: [],
      pasalOption: [],
      dpoDetail: [],
    };
  },
  methods: {
    init: function() {
      this.getPasal();
    },
    getPasal: function(){
      axios.get("idp_dropdown/list").then((response) => {
        this.pasalOption = response.data.pasal
      });
    },
    addCrime: function(){
      this.crimeModal = true;
    },
    addCatatanKriminal() {
      this.userid = this.user.id;
      this.show = true;
    },
    submitCrime() {
      this.$refs.usercrime.validate().then((success) => {
        if(success){
          this.$swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            background: '#262626',
            confirmButtonText: 'Yes, Submit it!',
            theme:'dark'
          }).then((result) => {
            // Jika pengguna menekan tombol "Yes", maka lakukan sesuatu
            if (result.isConfirmed) {
              this.LoadingCrime = true;
              setTimeout(() => {
                this.formCrime.id = this.$route.params.id
                this.formCrime.pasal = this.selectedPasal.value
                axios.post("idp/crime/add",this.formCrime).then((response) => {
                  if(response.data.status == 2000){
                    this.$swal({
                      title: "Success",
                      icon: "success",
                      background: "black",
                      confirmButtonColor: "#7367f0",
                      confirmButtonText: "Close",
                    });
                  }
                }).catch((error) => {
                  if (error.status === 4000) {
                    localStorage.clear();
                    this.$router.push({ path: "/login" });
                    ({
                      title: "Login Expired",
                      message: "Sesi Login Habis",
                    });
                  }
                }).finally(() => {
                  this.Loading = false;
                  this.crimeModal = false;
                  this.$emit('syncCrime',this.formCrime.id)
                });
              }, 1000);
            }
          }) 
        }
      });
    },
    removeData: function(row){
      this.$swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        background: '#262626',
        confirmButtonText: 'Yes, delete it!',
        theme:'dark'
      }).then((result) => {
        // Jika pengguna menekan tombol "Yes", maka lakukan sesuatu
        if (result.isConfirmed) {
          axios.post("idp/crime/delete-crime",{id:row.id}).then((response) => {
            if(response.data.status == 2000){
              this.$swal({
                title: "Success",
                icon: "success",
                background: "black",
                confirmButtonColor: "#7367f0",
                confirmButtonText: "Close",
              });
            }
          }).finally(() => {
            this.$emit('syncCrime',this.user.id)
          });
        }
      })
    },
    resetForm() {
      for (const i in _interfaces.lisensi) {
        _interfaces.lisensi[i] = null
      }
      this.formLisensi = _interfaces.lisensi
      this.selectedLicenseType = null
    },
    viewDetail(row){
      this.infoDPOModal = true
      axios.post("idp/user/dpo-info",{id:row.id}).then((response) => {
        this.dpoDetail = response.data.data
      });
    },
  },
  mounted() {
    this.init();
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.input-like-div {
  border: 1px solid #babcc2; /* Atur ketebalan dan warna border sesuai kebutuhan */
  padding: .375rem .75rem; /* Atur ukuran padding sesuai kebutuhan */
  border-radius: .25rem; /* Atur radius sudut untuk membuat kotak lebih halus */
  background-color: #babcc2; /* Atur warna latar belakang sesuai kebutuhan */
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out; /* Animasi saat hover */
}

.input-like-div:hover {
  border-color: #6c757d; /* Atur warna border saat hover */
}
</style>