<template>
  <div style="display:inline">
    <b-button @click="addCrime()" size="sm" variant="primary"><font-awesome-icon icon="plus"/> Catatan Kriminal</b-button>
    <br/>
    <br/>
    <b-table striped hover :items="crimeData" :fields="crimeFields" show-empty>
      <template v-slot:cell(action)="row">
        <b-button @click="viewDetail(row.item)" variant="primary" size="sm"><feather-icon icon="EyeIcon" /></b-button>&nbsp;&nbsp;
        <b-button v-if="isValidUser" @click="removeData(row.item)" variant="danger" size="sm"><feather-icon icon="TrashIcon" /></b-button>
      </template>
      <template #empty>
        <div class="text-center my-3">
          Tidak ada data yang tersedia saat ini.
        </div>
      </template>
    </b-table>

    <b-modal v-model="crimeModal" title="Tambah Catatan Kriminal" hide-footer size="lg">
      <loading v-if="LoadingCrime"></loading>
      <validation-observer ref="usercrime" tag="form" @submit.prevent="submitCrime">
        <b-form v-if="!LoadingCrime" @submit.prevent="submitCrime">
          <b-form-group label="Denda">
            <validation-provider #default="{ errors }" name="pasal" rules="required">
              <multiselect v-model="selectedPasal" :options="pasalOption" :multiple="false" :close-on-select="true" :clear-on-select="true" placeholder="Pilih Denda Tertinggi" label="text" track-by="value"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Kasus">
            <validation-provider #default="{ errors }" name="judul" rules="required">
              <b-form-input v-model="formCrime.judul" locale="en"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Keterangan / Pasal dikenakan">
            <validation-provider #default="{ errors }" name="Description" rules="required">
             <b-form-textarea id="description" v-model="formCrime.description" ></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Url Image">
            <validation-provider #default="{ errors }" name="link image" rules="required">
              <b-form-input v-model="formCrime.urlimg" locale="en"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button type="submit" variant="primary">Simpan</b-button>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal v-model="detailCrimeModal" title="Crime Detail" hide-footer size="lg" centered>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Kasus">
            <div class="input-like-div" style="color:black">
              {{ crimeDetail.judul }}
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group label="Denda">
            <div class="input-like-div" style="color:black">
              {{ crimeDetail.pasal_denda }}
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group label="Pasal Type">
            <div class="input-like-div" style="color:black">
              {{crimeDetail.pasal_type}}
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group label="Keterangan / Pasal Dikenakan">
            <div class="input-like-div" style="color:black">
              {{ crimeDetail.description }}
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group label="Petugas">
            <div class="input-like-div" style="color:black">
              {{ crimeDetail.inserted_by_name }}
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group label="Tanggal">
            <div class="input-like-div" style="color:black">
              {{ crimeDetail.formated_inserted_date }}
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect
  },
  props:{
    user: {
      type: [Array, Object],
      default: () => [],
    },
    crimeData: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      userid: null,
      Loading: false,
      LoadingCrime: false,
      crimeModal: false,
      selectedLicenseType : null,
      formCrime:_interfaces.catatankriminal,
      crimeDetail: [],
      isValidUser: false,
      detailCrimeModal: false,
      crimeFields: [
        { key: "judul", label: "Kasus" },
        // { key: "pasal_type", label: "Pelanggaran" },
        { key: "pasal_denda", label: "Denda" },
        // { key: "description", label: "Description" },
        // { key: "inserted_by_name", label: "Inserted By" },
        // { key: "formated_inserted_date", label: "Tanggal" },
        { key: "action", label: "Action" },
      ],
      selectedPasal: [],
      pasalOption: []
    };
  },
  methods: {
    init: function() {
      this.getPasal();
      this.checkValidAccessButton(9);
    },
    getPasal: function(){
      axios.get("idp_dropdown/list").then((response) => {
        this.pasalOption = response.data.pasal_denda
      });
    },
    addCrime: function(){
      this.resetForm();
      this.crimeModal = true;
    },
    addCatatanKriminal() {
      this.userid = this.user.id;
      this.show = true;
    },
    checkValidAccessButton(id_config){
      axios.post("idp/master/role-config/auth",{id:id_config}).then((response) => {
        this.isValidUser = response.data.valid
        console.log(this.isValidUser)
      })
    },
    submitCrime() {
      this.$refs.usercrime.validate().then((success) => {
        if(success){
          this.$swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            background: '#262626',
            confirmButtonText: 'Yes, Submit it!',
            theme:'dark'
          }).then((result) => {
            // Jika pengguna menekan tombol "Yes", maka lakukan sesuatu
            if (result.isConfirmed) {
              this.LoadingCrime = true;
              setTimeout(() => {
                this.formCrime.id = this.$route.params.id
                this.formCrime.pasal = this.selectedPasal.value
                axios.post("idp/crime/add",this.formCrime).then((response) => {
                  if(response.data.status == 2000){
                    this.$swal({
                      title: "Success",
                      icon: "success",
                      background: "black",
                      confirmButtonColor: "#7367f0",
                      confirmButtonText: "Close",
                    });
                  }
                }).catch((error) => {
                  if (error.status === 4000) {
                    localStorage.clear();
                    this.$router.push({ path: "/login" });
                    ({
                      title: "Login Expired",
                      message: "Sesi Login Habis",
                    });
                  }
                }).finally(() => {
                  this.Loading = false;
                  this.LoadingCrime = false;
                  this.crimeModal = false;
                  this.$emit('syncCrime',this.formCrime.id)
                });
              }, 1000);
            }
          }) 
        }
      });
    },
    removeData: function(row){
      this.$swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        background: '#262626',
        confirmButtonText: 'Yes, delete it!',
        theme:'dark'
      }).then((result) => {
        // Jika pengguna menekan tombol "Yes", maka lakukan sesuatu
        if (result.isConfirmed) {
          axios.post("idp/crime/delete-crime",{id:row.id}).then((response) => {
          }).finally(() => {
            this.$emit('syncCrime',this.user.id)
          });
        }
      })
    },
    resetForm() {
      for (const i in _interfaces.catatankriminal) {
        _interfaces.catatankriminal[i] = null
      }
      this.formCrime = _interfaces.catatankriminal
      this.selectedPasal = null
    },
    viewDetail(row){
      this.crimeDetail = row;
      this.detailCrimeModal = true;
    }
  },
  mounted() {
    this.init();
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.input-like-div {
  border: 1px solid #babcc2; /* Atur ketebalan dan warna border sesuai kebutuhan */
  padding: .375rem .75rem; /* Atur ukuran padding sesuai kebutuhan */
  border-radius: .25rem; /* Atur radius sudut untuk membuat kotak lebih halus */
  background-color: #babcc2; /* Atur warna latar belakang sesuai kebutuhan */
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out; /* Animasi saat hover */
}

.input-like-div:hover {
  border-color: #6c757d; /* Atur warna border saat hover */
}
</style>