<template>
    <div>
      <div class="row">
        <!-- Sidebar (4 kolom) -->
        <div class="col-md-4">
          <b-card class="user-profile-card">
            <div class="profile-info">
                <!-- Foto profil -->
                <loading v-if="Loading" />

                <div class="wanted-overlay profile-img text-center">
                  <img :src="require('@/assets/images/avatars/8.png')" v-if="user.imgurl == null && user.sex == 'm' || user.valid_img == false && user.sex == 'm'" style="min-width: 200px; min-height: 200px; max-width: 200px; max-height: 200px;">
                  <img :src="require('@/assets/images/avatars/11.png')" v-if="user.imgurl == null && user.sex == 'f' || user.valid_img == false && user.sex == 'f'" style="min-width: 200px; min-height: 200px; max-width: 200px; max-height: 200px;">
                  <img :src="user.imgurl" style="min-width: 200px; min-height: 200px; max-width: 200px; max-height: 200px;" v-if="user.imgurl != null && user.valid_img == true">
                  <div class="overlay-text" v-if="user.is_dpo">WANTED</div>
                  <br/>
                  <br/>
                  <b-button v-if="!Loading" size="sm" variant="primary" @click="changeProfilePhoto">Ganti Foto</b-button>
                </div>
                <br/>
                
                <!-- Informasi total project -->
                <div class="row" v-if="!Loading">
                  <!-- Bagian kiri total project -->
                    <div class="col-md-6">
                        <b-alert variant="success" show class="total-project-box">
                            <p>Total Lisensi</p>
                            <h3 style="color: white;">{{ user.total_license }}</h3>
                        </b-alert>
                    </div>
                    <!-- Bagian kanan total project -->
                    <div class="col-md-6">
                        <b-alert variant="info" show class="total-project-box">
                            <p>Total Kriminal</p>
                            <h3 style="color: white;">{{ user.total_kriminal }}</h3>
                        </b-alert>
                    </div>
                    <div class="col-md-12">
                      <b-button block variant="danger" size="sm" @click="showDPOModal" v-if="validAddDpo"><strong> AJUKAN SEBAGAI DPO </strong></b-button>
                      <!-- <b-button block variant="warning" size="sm" @click="showDPOInfo" v-if="user.request_release != 1"><strong> LIHAT STATUS DPO </strong></b-button> -->
                      <!-- <b-button block variant="warning" size="sm" v-if="user.request_release == 1"><strong> Dalam Proses Penghapusan </strong></b-button> -->
                      <b-button block variant="success" size="sm" @click="releaseDPO" v-if="user.is_dpo != null && validrequestDeleteDPO"><strong> AJUKAN PENGHAPUSAN DPO </strong></b-button>
                    </div>
                </div>
                <!-- Separator -->
                <hr>
                <!-- Profil singkat -->
                <loading v-if="Loading" />
                <div v-if="!Loading">
                    <p><strong>First Name  : </strong> {{ user.firstname }}</p>
                    <p><strong>Last Name  : </strong> {{ user.lastname }}</p>
                    <p><strong>Tanggal Lahir  : </strong> {{ user.dateofbirth }}</p>
                    <!-- <p><strong>Pekerjaan  : </strong> {{ user.job }}</p> -->
                    <p><strong>Golongan Darah  : </strong> {{ user.bloodtype }}</p>
                    <p><strong>Kenegaraan  : </strong> Indopride</p>
                    <hr/>
                    <p v-if="Role == 1">
                      <strong>Steam Hash  : </strong>
                      <i style="color:yellow">{{ user.identifier }}</i>&nbsp;
                      <b-button size="sm" @click="copyToClipboard(user.identifier)">Copy</b-button>
                    </p>
                    <!-- Tombol ubah foto profil -->
                </div>
            </div>
          </b-card>

        </div>


        <!-- Content (8 kolom) -->
        <div class="col-md-8">
          <b-card>
            <b-tabs>
              <b-tab title="Lisensi">
                <sub-license :user="user" :licenseData="licenseData" @syncLicense="syncLicense"></sub-license>
              </b-tab>
              <b-tab title="STNK">
                <sub-stnk :user="user" :stnkData="stnkData" @syncStnk="syncStnk"></sub-stnk>
              </b-tab>
              <b-tab title="Kendaraan">
                <sub-vehicle :vehicleData="vehicleData" :userData="user" :isLoading="isLoadingVehicle" @syncVehicle="syncVehicle"></sub-vehicle>
              </b-tab>
              <b-tab title="Catatan Kriminal">
                <sub-crime :user="user" :crimeData="crimeData" @syncCrime="syncCrime"></sub-crime>
              </b-tab>
              <b-tab title="Pelanggaran Lalu-Lintas">
                <sub-crime :user="user" :crimeData="crimeLalinData" @syncCrime="syncCrime"></sub-crime>
              </b-tab>
              <b-tab title="Status DPO Aktif">
                <sub-dpo :user="user" :dpoData="dpoData"></sub-dpo>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </div>
      <b-modal v-model="showImage" centered title="Bukti Foto" hide-footer>
        <div class="profile-img text-center">
          <img :src="require('@/assets/images/avatars/person.png')" style="width: 300px;" class="profile-image" alt="Profile Image">
        </div>
      </b-modal>
  
      <b-modal v-model="profileModal" centered title="Update Profile Pic" hide-footer>
        <validation-observer v-if="!Loading" ref="userprofilepic" tag="form" @submit.prevent="updatePP">
          <b-form @submit.prevent="updatePP">
            <b-form-group label="url image">
              <validation-provider #default="{ errors }" name="url Image" rules="required">
               <b-form-input id="url image" v-model="profilePic" ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button type="submit" variant="primary">Simpan</b-button>
          </b-form>
        </validation-observer>
      </b-modal>
  
      <b-modal v-model="dpoModal" centered title="Ajukan Sebagai DPO" hide-footer>
        <validation-observer v-if="!Loading" ref="dpo" tag="form" @submit.prevent="updateDPO">
          <b-form @submit.prevent="updateDPO">
            <b-form-group label="Judul Kasus">
              <validation-provider #default="{ errors }" name="judul kasus" rules="required">
               <b-form-input id="kasus" v-model="dpoForm.title" ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Kronologi Dijadikan DPO">
              <validation-provider #default="{ errors }" name="description" rules="required">
               <b-form-textarea id="description" v-model="dpoForm.description" ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Bukti Foto">
              <validation-provider #default="{ errors }" name="image" rules="">
               <b-form-input id="url image" v-model="dpoForm.image" ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Start">
              <validation-provider #default="{ errors }" name="start" rules="required">
               <b-form-datepicker id="start" v-model="dpoForm.start" ></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="End">
              <validation-provider #default="{ errors }" name="end" rules="required">
               <b-form-datepicker id="end" v-model="dpoForm.end" ></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button type="submit" variant="primary">Simpan</b-button>
          </b-form>
        </validation-observer>
      </b-modal>
  
      <b-modal v-model="infoDPOModal" centered title="Informasi Status DPO" hide-footer size="lg">
        <loading v-if="LoadingDPO" />
        <div v-else class="row">
          <div class="col-md-8">
            <b-card>
              <img :src="dpoData.image" style="width: 150px; border-radius: 0%;" class="profile-image" alt="Profile Image">
              <hr>
              <p>{{ dpoData.description }}</p>
            </b-card>
          </div>
          <div class="col-md-4">
            <div>
              Diajukan: <p>{{ dpoData.periode }} <br/>By : {{ dpoData.inserted_by_name }}</p>
            </div>
          </div>
        </div>
      </b-modal>
  
      <b-modal v-model="releaseDPOModal" centered title="Hapus Status DPO" hide-footer>
        
        <validation-observer ref="dpohapus" tag="form" @submit.prevent="submitReleaseDPO">
          <b-form @submit.prevent="submitReleaseDPO">
            <b-form-group label="Penghapusan Case">
              <validation-provider #default="{ errors }" name="Penghapusan Case" rules="required">
              <multiselect v-model="selectedDPOCase" :options="listDPOCase" :multiple="true" :close-on-select="true" :clear-on-select="true" placeholder="Pilih Kasus DPO" label="text" track-by="value"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Alasan Penghapusan DPO">
              <validation-provider #default="{ errors }" name="release reason" rules="required">
               <b-form-textarea id="release reason" v-model="dpoForm.release_reason" placeholder="Berikan keterangan yang jelas dan lengkap!"></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button type="submit" variant="primary">Simpan</b-button>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import interfaces from "@/templates/_interfaces";
import Multiselect from 'vue-multiselect';

// sub component
import SubLicense from './subcomponent/License.vue'
import SubStnk from './subcomponent/Stnk.vue'
import SubCrime from './subcomponent/Crime.vue'
import SubDpo from './subcomponent/Dpo.vue'
import SubVehicle from './subcomponent/Vehicle.vue'
import _interfaces from '@/templates/_interfaces';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    SubLicense,
    SubCrime,
    SubVehicle,
    SubDpo,
    SubStnk,
    Multiselect
  },
  data() {
    return {
      Role: localStorage.getItem('role'),
      isLoadingVehicle: false,
      Loading: true,
      LoadingLicense: true,
      LoadingDPO: false,
      user: [],
      cekStatusDpo: [],
      vehicleData: [],
      crime: [],
      showImage: false,
      showCatatanModal: false,
      formCatatanKriminal: interfaces.catatankriminal,
      tableFields:[],
      licenseData:[],
      crimeData:[],
      crimeLalinData:[],
      dpoData:[],
      stnkData: [],
      listDPOCase: [],
      selectedDPOCase: null,
      profileModal:false,
      dpoModal:false,
      dpoForm:_interfaces.dpo,
      profilePic:null,
      displayname: null,
      infoDPOModal: false,
      releaseDPOModal: false,
      tableData2: [
        { id: 4, name: 'Bob Brown', email: 'bob@example.com' },
        { id: 5, name: 'Emma Lee', email: 'emma@example.com' },
        { id: 6, name: 'Chris Davis', email: 'chris@example.com' }
      ],
      validAddDpo: false,
      validrequestDeleteDPO: false,
    };
  },
  methods: {
    init(){
      let id = this.$route.params.id;
      this.Loading = true;
      this.syncUserData(id);
      this.syncLicense()
      this.syncStnk()
      this.syncVehicle(id);  
      this.syncCrime(id);  
      this.showDPOList();
      this.checkValidAccessButton(1);
      this.checkValidAccessButton(3);
    },
    checkValidAccessButton(id_config){
      if(id_config == 1){
        axios.post("idp/master/role-config/auth",{id:id_config}).then((response) => {
          this.validAddDpo = response.data.valid
        })
      }else if(id_config == 3){
        axios.post("idp/master/role-config/auth",{id:id_config}).then((response) => {
          this.validrequestDeleteDPO = response.data.valid
        })
      }
    },
    vieImage(){
      this.showImage = true;
    },
    syncUserData(id){
      this.Loading = true;
      axios.post("idp/user/userdetail",{id:id}).then((response) => {
        this.user = response.data.user
        this.cekStatusDpo = response.data.status_dpo
      }).catch((error) => {
      }).finally(() => {
        this.Loading = false;
      });
    },
    syncLicense(){
      axios.post("idp/lisensi/sync-license",{id:this.$route.params.id}).then((response) => {
        this.licenseData = response.data.data
      });
    },
    syncStnk(){
      axios.post("idp/stnk/sync-stnk",{id:this.$route.params.id}).then((response) => {
        this.stnkData = response.data.data
      });
    },
    syncVehicle(id){
      this.isLoadingVehicle = true;
      axios.post("idp/vehicle/list",{id:id}).then((response) => {
        this.vehicleData = response.data.data
        this.isLoadingVehicle = false;
      });
    },
    syncCrime(id){
      axios.post("idp/crime/list",{id:id}).then((response) => {
        var dataReturn = response.data.data
        this.crimeLalinData = dataReturn.filter(item => item.pasal_type === 'Lalu-Lintas');
        this.crimeData = dataReturn.filter(item => item.pasal_type !== 'Lalu-Lintas');
      });
    },
    changeProfilePhoto(){
      this.profileModal = true;
    },
    updatePP(){
      this.$refs.userprofilepic.validate().then((success) => {
        if(success){ 
          this.Loading = true;
          axios.post("idp/user/profilepic",{id:this.user.id,pic:this.profilePic}).then((response) => {
          }).catch((error) => {
            if (error.status === 4000) {
              localStorage.clear();
              this.$router.push({ path: "/login" });
              ({
                title: "Login Expired",
                message: "Sesi Login Habis",
              });
            }
          }).finally(() => {
            this.Loading = false;
            this.showlicense = false;
            this.profileModal = false;
            this.syncUserData(this.$route.params.id);
          });
        }
      });
    },
    showDPOModal(){
      this.dpoModal = true
      this.resetForm();
    },
    updateDPO(){
      this.$refs.dpo.validate().then((success) => {
        if(success){ 
          this.$swal({
            title: "Anda Yakin?",
            text: "Data Warga akan diajukan kepada pimpinan",
            icon: "question",
            background: "black",
            showCancelButton: true,
            confirmButtonColor: "#7367f0",
            cancelButtonColor: "#ea5455",
            confirmButtonText: "Save",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                this.dpoForm.id = this.user.id
                axios.post("idp/user/dpo",this.dpoForm).then((response) => {
                  if(response.data.status == 2000){
                    this.$swal({
                      title: "Success",
                      icon: "question",
                      background: "black",
                      confirmButtonColor: "#7367f0",
                      confirmButtonText: "Close",
                    });
                  }else{
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "Update DPO Gagal",
                        icon: "EditIcon",
                        variant: "warning",
                        text: "Masih menjadi DPO",
                      },
                    });
                  }
                });
                this.dpoModal = false
                this.syncUserData(this.$route.params.id)
            } else {
              this.dpoModal = false
            }
          });
        }
      });
    },
    showDPOList(){
      this.LoadingDPO = true
      axios.post("idp/user/dpo-list",{id:this.$route.params.id}).then((response) => {
        this.dpoData = response.data.data
        this.listDPOCase = response.data.dropdown
      });
    },
    releaseDPO(){
      this.releaseDPOModal = true;
    },
    submitReleaseDPO(){
      this.$refs.dpohapus.validate().then((success) => {
        if(success){ 
          this.$swal({
            title: "Laporan akan diteruskan ke pimpinan",
            icon: "question",
            background: "black",
            showCancelButton: true,
            confirmButtonColor: "#7367f0",
            cancelButtonColor: "#ea5455",
            confirmButtonText: "Save",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                this.LoadingDPO = true
                setTimeout(() => {
                  this.dpoForm.id = this.selectedDPOCase
                  axios.post("idp/user/dpo-request-release",{id:this.selectedDPOCase,release_reason: this.dpoForm.release_reason}).then((response) => {
                  });
                  this.syncUserData(this.$route.params.id)
                  this.LoadingDPO = false
                  this.releaseDPOModal = false
      
                  this.$swal({
                    title: "Success",
                    icon: "question",
                    background: "black",
                    confirmButtonColor: "#7367f0",
                    confirmButtonText: "Close",
                  });
                }, 1000);

            } else {
              this.infoDPOModal = false
            }
          });
        }
      });
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text)
      .then(() => {
        this.$swal({
          title: "Coppied to clipboard!",
          icon: "info",
          background: "black",
          
        });
      })
      .catch(error => {
        console.error('Error copying text to clipboard:', error);
        // Handle error
      });
    },
    resetForm() {
      for (const i in _interfaces.dpo) {
        _interfaces.dpo[i] = null
      }
      this.dpoForm = _interfaces.dpo
      this.selectedPasal = null
    },
  },
  mounted(){
    this.init();
  }
}
</script>

<style scoped>
.small-img {
  width: 10px;
  height: 10px;
}

/* Style untuk profile page */
.profile-page {
  padding: 20px;
}

/* Style untuk bagian profil */
.profile-info {
  padding: 20px;
}

/* Style untuk foto profil */
.profile-img img {
  width: 100%;
  height: auto;
}

/* Style untuk konten profil singkat */
.profile-details {
  margin-top: 20px;
}

/* Style untuk tab navigasi */
.nav-link.active {
  background-color: #007bff;
  color: #fff;
}
.total-project-box {
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}

.total-project-box p {
  margin-bottom: 10px;
}

.total-project-box h3 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

@keyframes blinking {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.overlay-text {
    position: absolute;
    top: 18%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
    color: white; /* Ubah warna teks menjadi merah */
    background-color: red;
    padding: 20px 45px;
    border-radius: 5px;
    animation: blinking 1s infinite alternate; /* Terapkan animasi blinking */
}
</style>